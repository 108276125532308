<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label
              for="filter_search"
            >
              <strong>
                Search
              </strong>
            </label>
            <b-input
              id="filter_search"
              v-model="tableBorrowing.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            :disabled="state.busy"
            @click="onShowAddFormBorrowingItem"
          >
            Borrow Item
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="tableBorrowing"
        hover
        responsive
        show-empty
        class="mt-2"
        :per-page="tableBorrowing.perPage"
        :current-page="tableBorrowing.currentPage"
        :items="tableBorrowingProvider"
        :fields="tableBorrowing.fields"
        :sort-by.sync="tableBorrowing.sortBy"
        :sort-desc.sync="tableBorrowing.sortDesc"
        :sort-direction="tableBorrowing.sortDirection"
        :filter="tableBorrowing.filter"
        :filter-included-fields="tableBorrowing.filterOn"
        :tbody-tr-class="tableRowClass"
      >
        <template #cell(index)="row">
          {{ tableBorrowing.currentPage * tableBorrowing.perPage - tableBorrowing.perPage + (row.index + 1) }}
        </template>

        <template #cell(action)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              class="mr-1"
              :disabled="state.busy"
              @click="onViewBorrowing(row.item)"
            >
              View
            </b-button>
            <b-button
              size="sm"
              variant="danger"
              :disabled="state.busy"
              @click="onCancelBorrowing(row.item)"
            >
              Cancel
            </b-button>
          </div>
        </template>

        <template #cell()="row">
          <div class="text-nowrap">
            {{ row.value }}
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableBorrowing.perPage"
              :options="tableBorrowing.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableBorrowing.currentPage"
            :total-rows="tableBorrowing.totalRows"
            :per-page="tableBorrowing.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-borrowing"
      size="xl"
      scrollable
      no-close-on-backdrop
      :title="borrowingTitle"
      aria-hidden="false"
      @ok="onValidateBorrowingForm"
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <ValidationObserver
            ref="formBorrowing"
          >
            <form
              novalidate
              @submit.prevent
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="start date"
                    vid="start_date"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="start_date">
                        <strong>
                          Start
                        </strong>
                      </label>
                      <b-input
                        id="start_date"
                        v-model="borrowing.start_date"
                        type="datetime-local"
                        autocomplete="off"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy || state.viewing"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="end date"
                    vid="end_date"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="end_date">
                        <strong>
                          End
                        </strong>
                      </label>
                      <b-input
                        id="end_date"
                        v-model="borrowing.end_date"
                        type="datetime-local"
                        autocomplete="off"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy || state.viewing"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="purpose"
                    vid="purpose"
                    rules="required|max:500"
                  >
                    <b-form-group>
                      <label for="purpose">
                        <strong>
                          Purpose
                        </strong>
                      </label>
                      <b-textarea
                        id="purpose"
                        v-model="borrowing.purpose"
                        rows="5"
                        max-rows="12"
                        placeholder="enter purpose"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy || state.viewing"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  v-if="state.viewing"
                  cols="12"
                >
                  <b-form-group>
                    <label for="remarks">
                      <strong>
                        Remarks
                      </strong>
                    </label>
                    <b-textarea
                      id="remarks"
                      v-model="borrowing.remarks"
                      rows="5"
                      max-rows="12"
                      placeholder="enter remarks"
                      :disabled="state.busy || state.viewing"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </form>
          </ValidationObserver>
        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <ValidationObserver
            ref="formBorrowingItem"
          >
            <form
              novalidate
              @submit.prevent
            >
              <b-row>

                <b-col
                  v-if="!state.viewing"
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="template"
                    vid="template"
                    rules="required"
                  >
                    <b-form-group>
                      <label
                        class="col-12 px-0"
                        for="template"
                      >
                        <span class="d-flex justify-content-between">
                          <strong>
                            Template
                          </strong>
                          <i>
                            Remaining: <b class="text-success">{{ selected.template.items_count }}</b>
                          </i>
                        </span>
                      </label>
                      <b-form-select
                        id="template"
                        v-model="selected.template"
                        :options="list.templates"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="{
                              id: null,
                              template_name: null,
                              items_count: 0
                            }"
                            disabled
                          >
                            -- select template --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  v-if="!state.viewing"
                  cols="12"
                  md="6"
                >
                  <b-table
                    ref="tableTemplateCompositions"
                    hover
                    responsive
                    show-empty
                    class="mt-2"
                    :items="compositionFilter"
                    :fields="tableTemplateCompositions.fields"
                  >
                    <template #cell(index)="row">
                      {{ row.index + 1 }}
                    </template>
                    <template #head(action)="row">
                      <div class="text-nowrap text-center">
                        {{ row.label }}
                      </div>
                    </template>
                    <template #head(item_details_count)="row">
                      <div class="text-nowrap text-center">
                        {{ row.label }}
                      </div>
                    </template>
                    <template #cell(item_details_count)="row">
                      <div class="text-nowrap text-center">
                        <strong>{{ row.value }}</strong>
                      </div>
                    </template>
                    <template #cell(action)="row">
                      <div class="text-nowrap text-center">
                        <b-button
                          size="sm"
                          class="mr-1"
                          variant="success"
                          @click="onAddBorrowing(row.item)"
                        >
                          Add
                        </b-button>
                      </div>
                    </template>
                    <template #cell()="row">
                      <div class="text-nowrap">
                        {{ row.value }}
                      </div>
                    </template>
                  </b-table>
                </b-col>

                <b-col
                  cols="12"
                  :md="state.viewing ? 12 : 6"
                >
                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-table
                        ref="tableBorrowingItems"
                        hover
                        responsive
                        show-empty
                        class="mt-2"
                        :items="borrowing.items"
                        :fields="tableBorrowingItems.fields.filter(tableField => state.viewing ? ['template_name', 'category_name', 'status', 'serial_number', 'item_name', 'item_detail_name', 'remarks'].includes(tableField.key) : ['template_name', 'category_name', 'action'].includes(tableField.key))"
                      >
                        <template #cell(index)="row">
                          {{ row.index + 1 }}
                        </template>
                        <template #head(action)="row">
                          <div class="text-nowrap text-center">
                            {{ row.label }}
                          </div>
                        </template>
                        <template #cell(action)="row">
                          <div class="text-nowrap text-center">
                            <b-button
                              size="sm"
                              class="mr-1"
                              variant="danger"
                              @click="onRemoveBorrowing(row.item)"
                            >
                              Remove
                            </b-button>
                          </div>
                        </template>
                        <template #cell(status)="row">
                          <div class="text-nowrap">
                            <strong
                              v-if="row.value === 'Pending'"
                              class="text-warning"
                            >
                              {{ row.value }}
                            </strong>
                            <strong
                              v-if="row.value === 'Reserved'"
                              class="text-success"
                            >
                              {{ row.value }}
                            </strong>
                            <strong
                              v-if="row.value === 'Cancelled'"
                              class="text-danger"
                            >
                              {{ row.value }}
                            </strong>
                          </div>
                        </template>
                        <template #cell(serial_number)="row">
                          <div class="text-nowrap">
                            <strong>{{ row.value }}</strong>
                          </div>
                        </template>
                        <template #cell()="row">
                          <div class="text-nowrap">
                            {{ row.value }}
                          </div>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </b-col>

              </b-row>
            </form>
          </ValidationObserver>
        </b-col>
      </b-row>

      <template #modal-footer="{ok, cancel}">
        <b-button
          v-if="state.viewing === false"
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.viewing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { UserBorrowingService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'UserBorrowings',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Borrowings'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        viewing: false
      },
      fetching: {
        templates: false
      },
      list: {
        templates: [],
        compositions: []
      },
      selected: {
        template: {
          id: null,
          template_name: null,
          items_count: 0
        }
      },
      borrowing: {
        id: null,
        start_date: null,
        end_date: null,
        purpose: null,
        items: []
      },
      tableBorrowing: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'index', class: 'text-center' },
          { key: 'action', class: 'text-center' },
          { key: 'start_date', formatter: this.dateTimeShortFormatter },
          { key: 'end_date', formatter: this.dateTimeShortFormatter },
          { key: 'status', class: 'text-center' },
          { key: 'created_at', formatter: this.dateTimeShortFormatter }
        ]
      },
      tableTemplateCompositions: {
        fields: [
          { key: 'action', class: 'text-center' },
          { key: 'category_name', label: 'category' }
        ]
      },
      tableBorrowingItems: {
        fields: [
          { key: 'action', class: 'text-center' },
          { key: 'status', class: 'text-center' },
          { key: 'template_name', label: 'template' },
          { key: 'category_name', label: 'category' },
          { key: 'serial_number' },
          { key: 'item_name', label: 'item' },
          { key: 'item_detail_name', label: 'peripheral' },
          { key: 'remarks' }
        ]
      }
    }
  },

  computed: {
    borrowingTitle () {
      return this.state.viewing ? 'Borrowed Item' : 'Borrow Item'
    },

    compositionFilter () {
      return this.list.compositions.filter(composition => !this.borrowing.items.filter(filter => filter.template_id === this.selected.template.id).map(item => item.category_id).includes(composition.category_id))
    }

  },

  watch: {
    'selected.template' (template) {
      if (template.id) {
        this.getTemplate(template.id)
      } else {
        this.list.compositions = []
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableBorrowingProvider (option) {
      return await UserBorrowingService.get(
        this.objectToUrl({
          page: option.currentPage,
          per_page: option.perPage,
          sort: option.sortBy,
          sort_desc: option.sortDesc,
          filter_text: option.filter
        })
      ).then(({ data }) => {
        this.tableBorrowing.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    tableRowClass (item, type) {
      if (!item || type !== 'row') return
      if (this.dateTimeFormatter(item.end_date) <= this.dateTimeFormatter(Date.now()) && item.status === 'Issued') return 'table-danger'
    },

    async getTemplates () {
      this.fetching.templates = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getTemplates('with_item_count=1').then(({ data }) => {
          this.list.templates = data.filter(template => template.items_count > 0).map(({ id, template_name, items_count }) => ({
            text: template_name,
            value: {
              id: id,
              template_name: template_name,
              items_count: items_count
            }
          }))
          this.fetching.templates = this.state.busy = false
          resolve(data)
        })
      })
    },

    async getTemplate (template) {
      this.fetching.compositions = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getTemplateComposition(`template=${template}`).then(({ data }) => {
          this.list.compositions = data.filter(composition => (composition.item_details_count > 0 && composition.is_package === 0) || composition.is_parent === 1)
          this.fetching.compositions = this.state.busy = false
          resolve(data)
        })
      })
    },

    onShowAddFormBorrowingItem () {
      this.state.viewing = false

      this.borrowing.id = null
      this.borrowing.start_date = null
      this.borrowing.end_date = null
      this.borrowing.purpose = null
      this.borrowing.remarks = null
      this.borrowing.items = []

      this.getTemplates().then(() => {
        this.$bvModal.show('modal-borrowing')
      })
    },

    onViewBorrowing (borrowing) {
      this.state.viewing = true

      this.borrowing.id = borrowing.id
      this.borrowing.start_date = borrowing.start_date
      this.borrowing.end_date = borrowing.end_date
      this.borrowing.purpose = borrowing.purpose
      this.borrowing.remarks = borrowing.remarks

      const collection = []

      borrowing.borrowing_items.forEach(bi => {
        collection.push({
          id: bi.id,
          template_id: bi.template_id,
          template_name: bi.template_name,
          category_id: bi.category_id,
          category_name: bi.category_name,
          serial_number: bi.serial_number,
          item_name: bi.item_name,
          item_detail_name: null,
          remarks: bi.remarks,
          status: bi.status
        })

        borrowing.borrowing_item_details.filter(filter => filter.borrowing_item_id === bi.id).forEach(bid => {
          collection.push({
            id: bid.id,
            template_id: bid.template_id,
            template_name: bid.template_name,
            category_id: bid.category_id,
            category_name: bid.category_name,
            serial_number: bid.serial_number,
            item_name: null,
            item_detail_name: bid.item_detail_name,
            remarks: bid.remarks,
            status: bid.status
          })
        })
      })

      borrowing.borrowing_item_details.filter(filter => !collection.map(collected => collected.id).includes(filter.id)).forEach(bid => {
        collection.push({
          id: bid.id,
          template_id: bid.template_id,
          template_name: bid.template_name,
          category_id: bid.category_id,
          category_name: bid.category_name,
          serial_number: bid.serial_number,
          item_name: null,
          item_detail_name: bid.item_detail_name,
          remarks: bid.remarks,
          status: bid.status
        })
      })

      this.borrowing.items = collection

      this.$bvModal.show('modal-borrowing')
    },

    onAddBorrowing (item) {
      const validation = this.borrowing.items.findIndex(borrow => borrow.template_id === this.selected.template.id && borrow.category_id === item.category_id)
      if (validation >= 0) {
        this.$refs.formBorrowingItem.setErrors({
          template: [
            'The category already exists.'
          ]
        })
      } else {
        this.borrowing.items.push({
          template_id: this.selected.template.id,
          template_name: this.selected.template.template_name,
          category_id: item.category_id,
          category_name: item.category_name,
          is_parent: item.is_parent
        })
      }
    },

    onRemoveBorrowing (item) {
      const removableItem = this.borrowing.items.findIndex(filter => filter.category_id === item.category_id && filter.template_id === item.template_id)
      if (removableItem >= 0) {
        this.borrowing.items.splice(removableItem, 1)
      }
    },

    async onValidateBorrowingForm (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formBorrowing.validate().then(async success => {
        if (success && this.borrowing.items.length > 0) {
          let text = 'Do you really want to borrow these items?'
          if (this.state.viewing) {
            text = 'Do you really want to save changes?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: text,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = false
              return this.onPostBorrowingForm()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          if (this.borrowing.items.length <= 0) {
            this.$refs.formBorrowingItem.setErrors({
              template: [
                'Please add atleast one category.'
              ]
            })
          }

          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onPostBorrowingForm () {
      return new Promise(resolve => {
        UserBorrowingService.post(this.borrowing).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-borrowing')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.tableBorrowing.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formBorrowing.setErrors(error.message)
          resolve(error)
        })
      })
    },

    onCancelBorrowing (borrowing) {
      this.$swal.fire({
        icon: 'question',
        title: 'Question',
        text: 'Do you really want to cancel your borrowing?',
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Yes',
        cancelButtonColor: '#FF2929',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.state.busy = false
          return this.onPutCancelBorrowing({
            id: borrowing.id,
            status: 'Cancelled'
          })
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },

    async onPutCancelBorrowing (borrowing) {
      return new Promise(resolve => {
        UserBorrowingService.put(borrowing).then(({ data }) => {
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.tableBorrowing.refresh()
          })
          resolve(data)
        }).catch(error => {
          this.state.busy = false
          resolve(error)
        })
      })
    }

  }
}
</script>
<style>
  #modal-borrowing .modal-xl {
    width:100vw;
    max-width: none;
    min-height: 100vh;
    margin: 0
  }

  #modal-borrowing .modal-content {
    min-height: 100vh;
  }
</style>
